import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { isTreatmentBooking, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  AspectRatioWrapper,
  IconFavorite, IconOfferLocation,
  IconSocialShare,
  NamedLink,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {/* {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null} */}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    listingFields,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const isTreatmentBookingType = isTreatmentBooking(currentListing);

  const offerLocation = publicData?.offerLocation;
  const offerLocationOptions = listingFields?.find(f => f.key === 'offerLocation')?.enumOptions;
  const offerLocationLabel = offerLocationOptions?.find(o => offerLocation === o.option)?.label;

  const offerIntroduction = publicData?.offerIntroduction?.length > 80 ? publicData?.offerIntroduction?.substring(0, 80) + '...' : publicData?.offerIntroduction;
  const offerDuration = publicData?.offerDuration;

  const holisticHealthFocus = publicData?.holisticHealthFocus;
  const holisticHealthFocusOptions = listingFields?.find(f => f.key === 'holisticHealthFocus')?.enumOptions;
  const holisticHealthFocusLabels = holisticHealthFocusOptions?.filter(o => holisticHealthFocus?.find(t => t.includes(o.option)));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  return (
    <div className={classes}>
      {/* <div className={css.actionButtons}>
        <div
          className={classNames(css.actionButtonsItem, css.socialShare)}
          onClick={() => alert('Social share is under development')}
        >
          <IconSocialShare />
        </div>
        <div
          className={classNames(css.actionButtonsItem, css.addToFavorites)}
          onClick={() => alert('Add to favorites is under development')}
        >
          <IconFavorite />
        </div>
      </div> */}
      <NamedLink className={css.rootLink} name="ListingPage" params={{ id, slug }}>
        <div className={css.mainInfo}>

          <div className={css.mainInfoText}>
            {!!offerLocation && offerLocationLabel && (
              <div className={offerLocation === 'online' ? classNames(css.offerLocation, css.offerLocationOnline) : css.offerLocation}>
                {/*<IconOfferLocation type={offerLocation} />*/}
                {offerLocationLabel}
              </div>
            )}
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>


            {!!offerIntroduction && (
              <p className={css.offerIntroduction}>
                {offerIntroduction}
              </p>
            )}
          </div>

          <div className={css.mainInfoPrice}>
            {/*{!!holisticHealthFocusLabels?.length && (*/}
            {/*  <ul className={css.holisticHealthFocus}>*/}
            {/*    {holisticHealthFocusLabels.map(item => {*/}
            {/*      return (*/}
            {/*        <li className={css.holisticHealthFocusItem} key={item.option}>*/}
            {/*          {item.label}*/}
            {/*        </li>*/}
            {/*      )*/}
            {/*    })}*/}
            {/*  </ul>*/}
            {/*)}*/}

            <div className={css.priceHolder}>
              {!!offerDuration && (
                <span className={css.duration}>
                  {offerDuration}<span className={css.durationRange}> min</span>
                </span>
              )}

              <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
            </div>
          </div>

          {/* {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null} */}
        </div>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          customPadding='330'
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>

        {/*<AspectRatioWrapper*/}
        {/*  className={css.aspectRatioWrapper}*/}
        {/*  width={aspectWidth}*/}
        {/*  height={aspectHeight}*/}
        {/*  {...setActivePropsMaybe}*/}
        {/*>*/}
        {/*  <LazyImage*/}
        {/*    rootClassName={css.rootForImage}*/}
        {/*    alt={title}*/}
        {/*    image={firstImage}*/}
        {/*    variants={variants}*/}
        {/*    sizes={renderSizes}*/}
        {/*  />*/}
        {/*</AspectRatioWrapper>*/}
        {/*<div className={css.info}>*/}
        {/*  {isTreatmentBookingType && <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />}*/}
        {/*  <div className={css.mainInfo}>*/}
        {/*    <div className={css.title}>*/}
        {/*      {richText(title, {*/}
        {/*        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,*/}
        {/*        longWordClass: css.longWord,*/}
        {/*      })}*/}
        {/*    </div>*/}
        {/*    {showAuthorInfo ? (*/}
        {/*      <div className={css.authorInfo}>*/}
        {/*        <FormattedMessage id="ListingCard.author" values={{ authorName }} />*/}
        {/*      </div>*/}
        {/*    ) : null}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </NamedLink>
    </div>

  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
