import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, FieldCheckbox } from '../../components';

import css from './SelectMultipleWithInputFilter.module.css';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import arrayMutators from 'final-form-arrays';

const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options } = props;
  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map(optionConfig => {
          const { option, label } = optionConfig;
          const fieldId = `${id}.${option}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox id={fieldId} name={name} label={label} value={option} />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

export const IconSearch = props => {
  const { className } = props;
  return (
    <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4846 17.154L10.2231 10.8925C9.72311 11.3182 9.14811 11.6477 8.49811 11.881C7.84811 12.1144 7.19495 12.231 6.53861 12.231C4.93694 12.231 3.58136 11.6766 2.47186 10.5678C1.36253 9.45895 0.807861 8.1042 0.807861 6.50353C0.807861 4.9027 1.36228 3.54678 2.47111 2.43578C3.57994 1.32495 4.9347 0.769531 6.53536 0.769531C8.1362 0.769531 9.49211 1.3242 10.6031 2.43353C11.7139 3.54303 12.2694 4.89861 12.2694 6.50028C12.2694 7.19511 12.1463 7.86753 11.9001 8.51753C11.6539 9.16753 11.3309 9.72328 10.9309 10.1848L17.1924 16.4465L16.4846 17.154ZM6.53861 11.231C7.86545 11.231 8.98561 10.7743 9.89911 9.86078C10.8126 8.94745 11.2694 7.82728 11.2694 6.50028C11.2694 5.17328 10.8126 4.05311 9.89911 3.13978C8.98561 2.22628 7.86545 1.76953 6.53861 1.76953C5.21161 1.76953 4.09144 2.22628 3.17811 3.13978C2.26461 4.05311 1.80786 5.17328 1.80786 6.50028C1.80786 7.82728 2.26461 8.94745 3.17811 9.86078C4.09144 10.7743 5.21161 11.231 6.53861 11.231Z" fill="#21262A"/>
    </svg>
  );
};

const OptionsSearchFormComponent = (props) => {
  const divRef = useRef();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [divRef]);

  const { liveEdit, onChange, onSubmit, onCancel, onClear, isOpen, ...rest } = props;


  if (liveEdit && !onChange) {
    throw new Error('FilterForm: if liveEdit is true you need to provide onChange function');
  }

  if (!liveEdit && !(onCancel && onClear && onSubmit)) {
    throw new Error(
      'FilterForm: if liveEdit is false you need to provide onCancel, onClear, and onSubmit functions'
    );
  }
  const handleChange = formState => {
    props.handleOptionsSearch(formState.values)
    if (formState?.values?.optionString) {
      setDropdownOpen(true)
    }
  };

  const toggleDropdownOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen(!isDropdownOpen);
  };

  const handleCancel = () => {
    // reset the final form to initialValues

    form.reset();
    onCancel();
  };


  const formCallbacks = liveEdit ? { onSubmit: () => null } : { onSubmit, onCancel, onClear };

  return (
    <FinalForm
      {...props}
      {...formCallbacks}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          placeholder,
          handleSubmit,
          onChange,
          onCancel,
          form,
          intl,
          name,
          id,
          options,
          children,
          values,
          handleOptionsSearch,
        } = fieldRenderProps;

        const clear = intl.formatMessage({ id: 'FilterForm.clear' });
        const cancel = intl.formatMessage({ id: 'FilterForm.cancel' });
        const submit = intl.formatMessage({ id: 'FilterForm.submit' });
        const classes = classNames(rootClassName || css.optionsForm, className);

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
          >
            <FormSpy onChange={handleChange} />
            {children}

            <div className={css.inputHolder}>
              <FieldTextInput
                className={css.optionsInput}
                type='text'
                id='optionString'
                name='optionString'
                placeholder={placeholder}
                onFocus={e => toggleDropdownOpen(e)}
              />
              <IconSearch className={css.iconSearch} />
            </div>


            <div ref={divRef} >
              {isDropdownOpen && <div className={css.dropdown}>
                <div className={css.buttonsWrapper}>
                  {/*<button className={css.clearButton} type='button' onClick={onClear}>*/}
                  {/*  {clear}*/}
                  {/*</button>*/}
                  {/*<button className={css.cancelButton} type='button' onClick={handleCancel}>*/}
                  {/*  {cancel}*/}
                  {/*</button>*/}
                  <button className={css.submitButton} type='submit'>
                    {submit}
                  </button>
                </div>
                <GroupOfFieldCheckboxes
                  className={css.fieldGroupPlain}
                  name={name}
                  id={`${id}-checkbox-group`}
                  options={options}
                />
              </div>}
            </div>


          </Form>
        );
      }}
    />
  );
};

OptionsSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

OptionsSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,

};

const OptionsSearchForm = compose(injectIntl)(OptionsSearchFormComponent);
OptionsSearchForm.displayName = 'OptionsSearchForm';

export default OptionsSearchForm;
