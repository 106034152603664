import React, { Component } from 'react';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { parseSelectFilterOptions } from '../../util/search';

import { FieldCheckbox } from '../../components';

import FilterPlain from '../../containers/SearchPage/FilterPlain/FilterPlain';
import FilterPopup from '../../containers/SearchPage/FilterPopup/FilterPopup';
import OptionsSearchForm from './OptionsSearchForm';

import css from './SelectMultipleWithInputFilter.module.css';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options } = props;
  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox id={fieldId} name={name} label={option.label} value={option.key} />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = schemaType === 'multi-enum' && searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;

  return { [queryParamName]: value };
};

class SelectMultipleWithInputFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.state = {
      options: this.props.options,
      hideOptions: true,
      isOpen: false,
    };


    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }


  handleOptionsSearch = (val) => {
    const { options } = this.props;
    const optionString = val?.optionString || '';

    if (optionString !== undefined) {
      const foundOptions = options.filter(o => {
        return o?.option?.includes(optionString);
      }).sort(function (a, b) {
        if (a.option < b.option) return -1;
        if (a.option > b.option) return 1;
        return 0;
      });


      if (foundOptions?.length > 0) {
        this.setState({
          options: foundOptions,
        });
      }
    }
  };

  handleSubmit(values) {
    const { onSubmit, queryParamNames, name,  schemaType, searchMode} = this.props;
    this.setState({ isOpen: false });
    const queryParamName = getQueryParamName(queryParamNames);
    const usedValue = values ? values[name] : values;
    onSubmit(format(usedValue, queryParamName, schemaType, searchMode));
  }

  handleClear() {
    const { onSubmit, onClear } = this.props;
    this.setState({ isOpen: false });

    if (onClear) {
      onClear();
    }

    onSubmit(null);
  }

  handleCancel() {
    const { onSubmit, onCancel, initialValues } = this.props;
    this.setState({ isOpen: false });

    if (onCancel) {
      onCancel();
    }

    onSubmit(initialValues);
  }


  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      placeholder,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      schemaType,
      searchMode,
      intl,
      showAsPopup,
      ...rest
    } = this.props;


    return (<OptionsSearchForm
      handleOptionsSearch={this.handleOptionsSearch}
      placeholder={placeholder}
      name={name}
      id={`${id}-checkbox-group`}
      options={this.state.options}
      intl={intl}
      onSubmit={this.handleSubmit}
      onCancel={this.handleCancel}
      onClear={this.handleClear}
      isOpen={this.state.isOpen}
      {...rest}
    />)

  }
}

SelectMultipleWithInputFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectMultipleWithInputFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SelectMultipleWithInputFilter);
